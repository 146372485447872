<template>
  <div>
    <div class="header-section">
      <div class="container">
        <div class="row login">
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-md-8 login-inputs mb-3">
                <login-input></login-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Banner Section--->
    <section class="banner-section">
      <div class="container">
        <div class="banner-caption">
          <h2>DRA Sexual Harassment Prevention Training</h2>
          <p>
            Comply with
            California’s <br />
            Sexual Harassment Prevention Training requirements
          </p>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <!---Main Content--->
    <div class="content-area">
      <div class="container sexual-content">
        <div class="row pt-6 pb-2">
          <div class="col-md-12">
            <h1>Online Sexual Harassment Prevention Training</h1>
          </div>
        </div>
        <div class="row pt-2 pb-3">
          <div class="col-md-4">
            <img src="img/signup/image2.png" class="img-fluid" />
          </div>
          <div class="col-md-8">
            <p>
              The California Restaurant Association’s new learning platform and
              improved sexual harassment prevention training gives employers
              administrative access to view and manage their employee trainings.
              California law requires that any business with 5 or more employees
              provide <strong>two hours</strong> of sexual harassment and
              abusive conduct prevention training for all supervisors and
              <strong>one hour</strong> of training for nonsupervisory
              employees. Training is required within six months of hire or
              promotion and every two years thereafter.
            </p>
          </div>
        </div>

        <div class="row pt-2 pb-1">
          <div class="col-md-12 pb-2">
            <h2>For Supervisors:</h2>
            <p>
              This course trains supervisors to identify and eliminate workplace
              harassment and discrimination based on protected characteristics,
              by helping them understand the law, and by demonstrating how to
              appropriately respond to misconduct. It helps supervisors
              recognize and address inappropriate behavior before it creates a
              hostile work environment. It also helps build the skills necessary
              to recognize abusive behavior and other misconduct,
              microaggressions, and unconscious bias that may lead to workplace
              bullying, hostility, and reduced productivity and creativity. This
              course provides practical tips on creating a safe, inclusive work
              environment, including safe and positive options for bystander
              intervention.
            </p>
            <p>
              The course content satisfies the sexual harassment training
              mandates of California (including AB 1825, AB 2053, SB 396, and SB
              1343). The course engages faculty and staff members on how to
              identify and report sexual and interpersonal misconduct; and
              covers awareness and prevention education topics required by the
              Clery Act and Title IX (including state law definitions of
              consent, sexual assault, domestic and dating violence, stalking,
              and victim protections). For supervisors, the training is
              <b>120 minutes</b>.
            </p>
          </div>
          <div class="col-md-12 pt-2">
            <h2>For Non-Supervisors:</h2>
            <p>
              This course engages employees and raises their awareness about
              harassment and discrimination by explaining the law and providing
              insight on how to appropriately respond to and report misconduct.
              It provides practical tips to help maintain a safe, inclusive work
              environment, including safe and positive options for bystander
              intervention, using inclusive language, and avoiding
              microaggressions. Interactive exercises engage learners with
              realistic situations, scenarios, tasks, and model strategies for
              promoting a harassment- and discrimination-free workplace. The
              course content satisfies the state sexual harassment training
              mandates of California (including AB 1825, AB 2053, SB 396, and SB
              1343). For non-supervisors, the training is <b>60 minutes</b>.
            </p>
          </div>
          <div class="col-md-12 pt-4 pb-4">
            <a
              href="https://cra.train321.com/#/user_register?course_type='sexual_harrasment'&inner='s'"
              class="btn ctm-btn"
              >Individuals</a
            >
            <a
              href="https://cra.train321.com/#/register?course_type='sexual_harrasment'&inner='s'"
              class="btn ctm-btn companies"
              >Companies</a
            >
          </div>
        </div>
      </div>
    </div>
    <!---Main Content end--->
  </div>
</template>
<script>
import LoginInput from "@/views/Pages/LoginInput.vue";
import "sweetalert2/src/sweetalert2.scss";
import BaseInput from "../../components/Inputs/BaseInput.vue";

export default {
  components: { BaseInput, LoginInput },
  data() {
    return {};
  },
   mounted() {
  
      this.$gtag.event("Sexual Harassment Info", { method: "Google" }); 
  
  },
  created: function() {},
  methods: {}
};
</script>
<style scoped>
.ctm-btn {
  background-color: #a8312d;
  color: #ffffff;
  border-radius: 0 !important;
  font-size: 17px;
  margin-right: 5px;
}
.login-inputs {
  padding-top: 20px;
}
.login {
  margin-right: 0px !important;
}
</style>
